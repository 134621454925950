var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-sidebar",
        {
          attrs: {
            "sidebar-class": "big_sidebar create-other-message",
            id: "add-nuiteq-meeting",
            right: "",
            backdrop: "",
            "no-slide": "",
            lazy: "",
            shadow: "",
            "no-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: { shown: _vm.shownSidebar, hidden: _vm.closeBtn },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function ({ hide }) {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { "cols-md": "6" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/img/external/nuiteq.svg",
                              width: "150px",
                            },
                          }),
                          _vm.GetTitle
                            ? _c("strong", { staticClass: "ml-2 mr-2" }, [
                                _vm._v(_vm._s(_vm.GetTitle)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { "cols-md": "6" } },
                        [
                          !_vm.changeTime && !_vm.IsDraft && _vm.recurrence
                            ? _c(
                                "b-alert",
                                {
                                  staticClass: "small-text mb-0",
                                  attrs: {
                                    show: "",
                                    dismissible: "",
                                    variant: "danger",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t(
                                          "CHANGE_MEETING_INFORMATION_SERIE"
                                        )
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function ({ hide }) {
                return [
                  !_vm.nuiteqAuthenticated
                    ? _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c("b-button", { on: { click: _vm.closeBtn } }, [
                            _vm._v(_vm._s(_vm.$t("CLOSE"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.nuiteqAuthenticated
                    ? _c("div", { staticClass: "ml-2" }, [
                        _vm.changeAttachments
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.step == 1,
                                      expression: "step == 1",
                                    },
                                  ],
                                  staticClass: "ml-2",
                                },
                                [
                                  _c(
                                    "b-button",
                                    { on: { click: _vm.closeBtn } },
                                    [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        !_vm.changeAttachments
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.step == 1,
                                      expression: "step == 1",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { disabled: _vm.saving },
                                      on: { click: _vm.closeBtn },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                                  ),
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSave,
                                            variant: "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveMeeting(true)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVE_DRAFT"))
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVING")) +
                                                    ".."
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.changeTime
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled: _vm.disabledStep2,
                                            variant: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.step = 2
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                      )
                                    : _vm._e(),
                                  _vm.changeTime
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSend,
                                            variant: "primary",
                                          },
                                          on: { click: _vm.UpdateBtn },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("UPDATE")))]
                                      )
                                    : _vm._e(),
                                  (_vm.CreateMeetingDTO.message_uuid != "" ||
                                    _vm.message_uuid != "") &&
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill float-right",
                                          attrs: {
                                            disabled: _vm.saving,
                                            variant: "danger",
                                          },
                                          on: { click: _vm.RemoveDraft },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.step == 2,
                                      expression: "step == 2",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 1
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                                  ),
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSave,
                                            variant: "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveMeeting(true)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVE_DRAFT"))
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVING")) +
                                                    ".."
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-fill",
                                      attrs: {
                                        disabled: _vm.disabledStep3,
                                        variant: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 3
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                  ),
                                  (_vm.CreateMeetingDTO.message_uuid != "" ||
                                    _vm.message_uuid != "") &&
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill float-right",
                                          attrs: {
                                            disabled: _vm.saving,
                                            variant: "danger",
                                          },
                                          on: { click: _vm.RemoveDraft },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.step == 3,
                                      expression: "step == 3",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 2
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                                  ),
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSave,
                                            variant: "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveMeeting(true)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVE_DRAFT"))
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVING")) +
                                                    ".."
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-fill",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 4
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("NEXT")))]
                                  ),
                                  (_vm.CreateMeetingDTO.message_uuid != "" ||
                                    _vm.message_uuid != "") &&
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill float-right",
                                          attrs: {
                                            disabled: _vm.saving,
                                            variant: "danger",
                                          },
                                          on: { click: _vm.RemoveDraft },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.step == 4,
                                      expression: "step == 4",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.step = 3
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                                  ),
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSave,
                                            variant: "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveMeeting(true)
                                            },
                                          },
                                        },
                                        [
                                          !_vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVE_DRAFT"))
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.saving
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("SAVING")) +
                                                    ".."
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSend,
                                            variant: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveMeeting(false)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                                      )
                                    : _vm._e(),
                                  !_vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill",
                                          attrs: {
                                            disabled:
                                              _vm.saving || _vm.disabledSend,
                                            variant: "primary",
                                          },
                                          on: { click: _vm.UpdateBtn },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("UPDATE")))]
                                      )
                                    : _vm._e(),
                                  (_vm.CreateMeetingDTO.message_uuid != "" ||
                                    _vm.message_uuid != "") &&
                                  _vm.IsDraft
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-fill float-right",
                                          attrs: {
                                            disabled: _vm.saving,
                                            variant: "danger",
                                          },
                                          on: { click: _vm.RemoveDraft },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: !_vm.CreateMeetingDTO.isDraft && _vm.saving,
                rounded: "sm",
              },
            },
            [
              _vm.loaded
                ? _c("div", { staticClass: "pb-4" }, [
                    _vm.settings
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "px-3 py-2 view-content create-message",
                          },
                          [
                            _vm.changeAttachments && _vm.recurrence
                              ? _c(
                                  "b-alert",
                                  {
                                    staticClass:
                                      "small-text pl-1 pt-1 pb-1 pr-1",
                                    attrs: { show: "", variant: "danger" },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("CHANGE_MEETING_ATTACHMENTS")
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.CreateMeetingDTO.settings.loa_level != 3 &&
                            !_vm.IsDraft &&
                            !_vm.changeTime
                              ? _c(
                                  "b-alert",
                                  {
                                    staticClass:
                                      "small-text pl-1 pt-1 pb-1 pr-1",
                                    attrs: {
                                      dismissible: "",
                                      show: "",
                                      variant: "warning",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("CHANGE_MEETING_LOA", {
                                            loa_level:
                                              _vm.CreateMeetingDTO.settings
                                                .loa_level,
                                          })
                                        ) +
                                        "\n            "
                                    ),
                                    _c("InputLoa", {
                                      attrs: {
                                        bold: "",
                                        loa: _vm.CreateMeetingDTO.settings
                                          .loa_level,
                                      },
                                    }),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("CHANGE_MEETING_WARNING1")
                                      ) + "\n            "
                                    ),
                                    _vm.CreateMeetingDTO.settings.loa_level < 3
                                      ? _c(
                                          "div",
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-circle-small",
                                            }),
                                            _vm._v("  \n              "),
                                            _c("InputLoa", {
                                              attrs: {
                                                loa: 3,
                                                "show-info": "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.CreateMeetingDTO.settings.loa_level < 2
                                      ? _c(
                                          "div",
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-circle-small",
                                            }),
                                            _vm._v("  \n              "),
                                            _c("InputLoa", {
                                              attrs: {
                                                loa: 2,
                                                "show-info": "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("CHANGE_MEETING_WARNING2")
                                        ) +
                                        "\n          "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.nuiteqAuthenticated
                              ? _c(
                                  "div",
                                  [
                                    _c("b-form-input", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        trim: "",
                                        "input-id": "subject",
                                        autocomplete: "off",
                                        placeholder: _vm.$t("USERNAME"),
                                      },
                                      model: {
                                        value: _vm.username,
                                        callback: function ($$v) {
                                          _vm.username = $$v
                                        },
                                        expression: "username",
                                      },
                                    }),
                                    _c("b-form-input", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        trim: "",
                                        type: "password",
                                        "input-id": "subject",
                                        autocomplete: "off",
                                        placeholder: _vm.$t("PASSWORD"),
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function ($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password",
                                      },
                                    }),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-fill",
                                        attrs: { variant: "primary" },
                                        on: { click: _vm.authenticatedBtn },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("LOGIN")))]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.nuiteqAuthenticated && _vm.CreateMeetingDTO
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.step == 1,
                                          expression: "step == 1",
                                        },
                                      ],
                                    },
                                    [
                                      _vm.IsDraft ||
                                      (_vm.isChange &&
                                        !_vm.changeTime &&
                                        !_vm.changeAttachments)
                                        ? _c(
                                            "div",
                                            [
                                              _c("InputSubject", {
                                                attrs: {
                                                  settings: _vm.settings,
                                                  limit: 256,
                                                },
                                                on: {
                                                  valid: (n) =>
                                                    (_vm.validatedSubject = n),
                                                },
                                                model: {
                                                  value:
                                                    _vm.CreateMeetingDTO
                                                      .subject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.CreateMeetingDTO,
                                                      "subject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "CreateMeetingDTO.subject",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("ADD_RECIPIENTS")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("InputParticipants", {
                                                key:
                                                  "InputParticipants_" +
                                                  _vm.externalParticipantsKey,
                                                attrs: {
                                                  message_uuid:
                                                    _vm.CreateMeetingDTO
                                                      .message_uuid,
                                                  message_type:
                                                    "nuiteq-meeting",
                                                  "is-meeting": "",
                                                  participants:
                                                    _vm.CreateMeetingDTO
                                                      .participants,
                                                  external_participants:
                                                    _vm.CreateMeetingDTO
                                                      .external_participants,
                                                },
                                                on: {
                                                  IsTyping: _vm.IsTyping,
                                                  UpdatedParticipants:
                                                    _vm.UpdatedParticipants,
                                                },
                                              }),
                                              _vm.IsDraft
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mt-2" },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "NUITEQ.STAGE_MEETING"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.settings
                                                      .user_meetings_enabled
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-2",
                                                          },
                                                          [
                                                            !_vm.selectedMeeting
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "NONE_SELECTED"
                                                                          )
                                                                        ) +
                                                                        "\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.selectedMeeting
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .selectedMeeting
                                                                            .name
                                                                        ) +
                                                                        "\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  size: "sm",
                                                                  variant:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.generateStageMeeting,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "NUITEQ.GENERATE_MEETING"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  size: "sm",
                                                                  variant:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.viewMeetings,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "NUITEQ.CREATED_MEETING_IN_STAGE"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-modal",
                                                                    rawName:
                                                                      "v-b-modal.save-new-meeting",
                                                                    modifiers: {
                                                                      "save-new-meeting": true,
                                                                    },
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  size: "sm",
                                                                  variant:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fal fa-plus",
                                                                }),
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "NUITEQ.CREATE_MEETING"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.changeTime && _vm.recurrence
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    label: _vm.$t("UPDATE"),
                                                    "label-cols-md": "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        name: "edit-recurring-single",
                                                        value: "SINGLE",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.ChangedEditRecurring,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editRecurring,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.editRecurring =
                                                            $$v
                                                        },
                                                        expression:
                                                          "editRecurring",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "EDIT_RECURRENCE_SINGLE"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        name: "edit-recurring-multiple",
                                                        value: "MULTIPLE",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.ChangedEditRecurring,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editRecurring,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.editRecurring =
                                                            $$v
                                                        },
                                                        expression:
                                                          "editRecurring",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "EDIT_RECURRENCE_MULTIPLE"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        name: "edit-recurring-all",
                                                        value: "ALL",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.ChangedEditRecurring,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editRecurring,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.editRecurring =
                                                            $$v
                                                        },
                                                        expression:
                                                          "editRecurring",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "EDIT_RECURRENCE_ALL"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.IsDraft
                                        ? _c("div", [
                                            _c("div", { staticClass: "mt-2" }, [
                                              _vm._v(_vm._s(_vm.$t("TIME"))),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _vm.changeTime || _vm.IsDraft
                                        ? _c(
                                            "div",
                                            [
                                              _vm.IsDraft && _vm.eventExpired
                                                ? _c(
                                                    "b-alert",
                                                    {
                                                      staticClass:
                                                        "pl-1 pt-1 pb-1 pr-1",
                                                      attrs: {
                                                        show: "",
                                                        variant: "warning",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "EVENT_EXPIRED"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("PickDate", {
                                                attrs: {
                                                  "use-label": _vm.$t("FROM"),
                                                  "use-clock": "",
                                                  "is-meeting": "",
                                                  "disable-date":
                                                    _vm.changeTime &&
                                                    _vm.editRecurring ==
                                                      "MULTIPLE",
                                                },
                                                on: {
                                                  valid: (n) =>
                                                    (_vm.validMeetingStart = n),
                                                },
                                                model: {
                                                  value:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_start_date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.CreateMeetingDTO
                                                        .settings,
                                                      "meeting_start_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "CreateMeetingDTO.settings.meeting_start_date",
                                                },
                                              }),
                                              _c("PickDate", {
                                                attrs: {
                                                  "start-date":
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_start_date,
                                                  "use-label": _vm.$t("TO"),
                                                  "use-clock": "",
                                                  "is-meeting": "",
                                                  "disable-date":
                                                    _vm.changeTime &&
                                                    _vm.editRecurring ==
                                                      "MULTIPLE",
                                                },
                                                on: {
                                                  valid: (n) =>
                                                    (_vm.validMeetingEnd = n),
                                                },
                                                model: {
                                                  value:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_end_date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.CreateMeetingDTO
                                                        .settings,
                                                      "meeting_end_date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "CreateMeetingDTO.settings.meeting_end_date",
                                                },
                                              }),
                                              _vm.startDifference
                                                ? _c("div", [
                                                    _vm.startDifference.days !=
                                                    0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "error-text mb-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "ERROR.MEETING_SPANS_DAYS",
                                                                    {
                                                                      days: _vm
                                                                        .startDifference
                                                                        .days,
                                                                    }
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                              _c("PickRecurrence", {
                                                attrs: {
                                                  disabled:
                                                    _vm.disableRecurrence,
                                                  serieStartDate:
                                                    _vm.serieStartDate,
                                                  startDate:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_start_date,
                                                  endDate:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_end_date,
                                                },
                                                on: {
                                                  valid: (n) =>
                                                    (_vm.validRecurrence = n),
                                                  changed: () =>
                                                    (_vm.recurrenceIndex += 1),
                                                },
                                                model: {
                                                  value:
                                                    _vm.CreateMeetingDTO
                                                      .recurrence,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.CreateMeetingDTO,
                                                      "recurrence",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "CreateMeetingDTO.recurrence",
                                                },
                                              }),
                                              _c("PickRecurrence", {
                                                key:
                                                  "recurrenceIndex_" +
                                                  _vm.recurrenceIndex,
                                                attrs: {
                                                  recurrence:
                                                    _vm.CreateMeetingDTO
                                                      .recurrence,
                                                  serieStartDate:
                                                    _vm.serieStartDate,
                                                  startDate:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_start_date,
                                                  endDate:
                                                    _vm.CreateMeetingDTO
                                                      .settings
                                                      .meeting_end_date,
                                                  "text-only": "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.changeTime && !_vm.changeAttachments
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-light fa-lock-keyhole-open",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "INVITATION_INFORMATION"
                                                        )
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              ),
                                              _c("InputExternalText", {
                                                attrs: {
                                                  settings: _vm.settings,
                                                  rows: 5,
                                                  "is-meeting": "",
                                                  limit: 1500,
                                                },
                                                on: {
                                                  valid: (n) =>
                                                    (_vm.validatedExternalText =
                                                      n),
                                                },
                                                model: {
                                                  value:
                                                    _vm.CreateMeetingDTO
                                                      .external_text,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.CreateMeetingDTO,
                                                      "external_text",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "CreateMeetingDTO.external_text",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.step == 2,
                                          expression: "step == 2",
                                        },
                                      ],
                                    },
                                    [
                                      _c("div", { staticClass: "mt-2" }, [
                                        _c("i", {
                                          staticClass:
                                            "fa-light fa-lock-keyhole",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("MEETING_NOTES"))
                                        ),
                                      ]),
                                      _c("InputBody", {
                                        attrs: {
                                          settings: _vm.settings,
                                          rows: 8,
                                          limit: 20000,
                                        },
                                        on: {
                                          valid: (n) => (_vm.validatedBody = n),
                                        },
                                        model: {
                                          value: _vm.CreateMeetingDTO.body,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.CreateMeetingDTO,
                                              "body",
                                              $$v
                                            )
                                          },
                                          expression: "CreateMeetingDTO.body",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  (_vm.step == 2 && _vm.IsDraft) ||
                                  _vm.changeAttachments
                                    ? _c("div", [
                                        _c("div", { staticClass: "mt-2" }, [
                                          _vm._v(_vm._s(_vm.$t("ADD_FILES"))),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            key:
                                              "AddFiles" +
                                              _vm.CreateMeetingDTO.message_uuid,
                                          },
                                          [
                                            _c("InputFiles", {
                                              attrs: {
                                                message_uuid:
                                                  _vm.CreateMeetingDTO
                                                    .message_uuid,
                                                "show-warning":
                                                  _vm.changeAttachments,
                                                "cannot-upload":
                                                  _vm.$t("DRAFT.SEFOS_UPLOAD"),
                                                "max-size": 200,
                                              },
                                              on: {
                                                updated: (n) =>
                                                  (_vm.updatedFiles = n),
                                                valid: (n) =>
                                                  (_vm.validatedFiles = n),
                                              },
                                              model: {
                                                value:
                                                  _vm.CreateMeetingDTO
                                                    .attachments,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.CreateMeetingDTO,
                                                    "attachments",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "CreateMeetingDTO.attachments",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.step == 3,
                                          expression: "step == 3",
                                        },
                                      ],
                                    },
                                    [
                                      _c("div", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("MESSAGES.LOA_LEVELS")
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                      _c("InputLoa", {
                                        model: {
                                          value:
                                            _vm.CreateMeetingDTO.settings
                                              .loa_level,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.CreateMeetingDTO.settings,
                                              "loa_level",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "CreateMeetingDTO.settings.loa_level",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.step == 4,
                                          expression: "step == 4",
                                        },
                                      ],
                                    },
                                    [
                                      _c("div", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "MESSAGES.NOT_REGISTERED_INFORMATION"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          key:
                                            "NotRegistedLoa_" +
                                            _vm.CreateMeetingDTO.settings
                                              .loa_level +
                                            "_" +
                                            _vm.CreateMeetingDTO
                                              .external_participants,
                                          staticClass: "mt-2",
                                        },
                                        [
                                          _vm.CreateMeetingDTO
                                            .external_participants.length == 0
                                            ? _c(
                                                "b-alert",
                                                {
                                                  staticClass: "mb-0 p-1",
                                                  attrs: {
                                                    show: "",
                                                    variant: "warning",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("NO_RECIPIENTS")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.CreateMeetingDTO
                                              .external_participants,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "NotRegistered",
                                                },
                                                [
                                                  _c("NotRegistered", {
                                                    attrs: {
                                                      message_type: "message",
                                                      methods: _vm.methods,
                                                      showSefos: item.showSefos,
                                                      loa_level:
                                                        _vm.CreateMeetingDTO
                                                          .settings.loa_level,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.UpdatedNotRegistered,
                                                      remove: function (
                                                        $event
                                                      ) {
                                                        return _vm.RemoveNotRegistered(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.CreateMeetingDTO
                                                          .external_participants[
                                                          index
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.CreateMeetingDTO
                                                            .external_participants,
                                                          index,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "CreateMeetingDTO.external_participants[index]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { variant: "secondary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.step = 1
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("ADD_MORE_PARTICIPANTS")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.step == 22,
                                          expression: "step == 22",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { key: _vm.meetingsNotInTrash.length },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "no-border",
                                              on: { click: _vm.getMeetings },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fal fa-sync",
                                              }),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("NUITEQ.SELECT_MEETING")
                                              ) +
                                              "\n\n                "
                                          ),
                                          _c("b-table", {
                                            attrs: {
                                              striped: "",
                                              hover: "",
                                              "per-page": "10",
                                              "current-page":
                                                _vm.currentMeetingPage,
                                              items: _vm.meetingsNotInTrash,
                                              fields: _vm.nuiteqMeetingsFields,
                                            },
                                            on: {
                                              "row-clicked": _vm.setMeeting,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(name)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            target: "blank",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setMeeting(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "USE_THIS_MEETING"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            data.item.name
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(id)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            target: "blank",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.joinBoard(
                                                                data.item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("JOIN")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hidden-not-mobile",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.item.name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4106479967
                                            ),
                                          }),
                                          _c("b-pagination", {
                                            attrs: {
                                              "total-rows":
                                                _vm.meetingsNotInTrash.length,
                                              "per-page": "10",
                                              "aria-controls": "my-table",
                                            },
                                            model: {
                                              value: _vm.currentMeetingPage,
                                              callback: function ($$v) {
                                                _vm.currentMeetingPage = $$v
                                              },
                                              expression: "currentMeetingPage",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: _vm.cancelSelectMeeting,
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          key: "SaveMeeting" + _vm.SaveMeetingIndex,
          attrs: {
            id: "save-new-meeting",
            "hide-header": "",
            centered: "",
            "ok-disabled": _vm.meetingName == "",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("SAVE"),
          },
          on: {
            cancel: _vm.cancelSave,
            hidden: _vm.closedNewMeeting,
            ok: _vm.okSave,
          },
        },
        [
          _c("b-img", {
            staticClass: "mb-2",
            attrs: { src: "/img/external/nuiteq.svg", alt: "nuiteq logo" },
          }),
          _c(
            "div",
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("NUITEQ.CREATE_MEETING_TITLE")) +
                  "\n\n      "
              ),
              _c("b-form-input", {
                attrs: {
                  trim: "",
                  placeholder: _vm.$t("NUITEQ.MEETING_NAME"),
                  autocomplete: "off",
                  name: _vm.meetingNameInput,
                },
                model: {
                  value: _vm.meetingName,
                  callback: function ($$v) {
                    _vm.meetingName = $$v
                  },
                  expression: "meetingName",
                },
              }),
              _c("b-form-input", {
                staticClass: "mt-2",
                attrs: {
                  type: _vm.meetingPasswordType,
                  autocomplete: "off",
                  name: _vm.meetingPasswordInput,
                  placeholder: _vm.$t("PASSWORD"),
                },
                on: {
                  click: function ($event) {
                    _vm.meetingPasswordType = "password"
                  },
                  blur: _vm.meetingPasswordBlur,
                },
                model: {
                  value: _vm.meetingPassword,
                  callback: function ($$v) {
                    _vm.meetingPassword = $$v
                  },
                  expression: "meetingPassword",
                },
              }),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: {
                    id: "nuiteq-meeting-waiting-room",
                    name: "checkbox-1",
                    value: true,
                    switch: "",
                    "unchecked-value": false,
                  },
                  model: {
                    value: _vm.meetingWaitingroom,
                    callback: function ($$v) {
                      _vm.meetingWaitingroom = $$v
                    },
                    expression: "meetingWaitingroom",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("NUITEQ.WAITING_ROOM_ACTIVE")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          key:
            "AcceptAlotUsers" +
            _vm.CreateMeetingDTO.external_participants.length,
          attrs: {
            id: "show-accept-alot-users",
            "hide-header": "",
            centered: "",
            "cancel-title": _vm.$t("NO"),
            "ok-title": _vm.$t("YES"),
          },
          on: { cancel: _vm.CancelAcceptAlotUsers, ok: _vm.OkAcceptAlotUsers },
          model: {
            value: _vm.ShowAcceptAlotUsers,
            callback: function ($$v) {
              _vm.ShowAcceptAlotUsers = $$v
            },
            expression: "ShowAcceptAlotUsers",
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("SEND_ALOT_OF_USERS", {
                  amount: _vm.CreateMeetingDTO.external_participants.length,
                })
              ) +
              "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }